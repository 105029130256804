import React, { useEffect, useRef, useState } from 'react';
import { TImage } from '../../../../libs/model';
import { getImageURL } from '../../../../libs/utils';

interface IProps {
  image: TImage;
}

const LazyImage: React.FC<IProps> = (props: IProps) => {
  const { image } = props;
  const { attributes } = image;
  const {
    formats, url,
  } = attributes;
  const { large, thumbnail } = formats;
  const targetRef = useRef(null);
  const imageRef = useRef<any>(null);
  const [isOnError, setIsOnError] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const io = new IntersectionObserver((entries) => {
      entries.forEach((item: any) => {
        // 当前元素可见时
        if (item.isIntersecting) {
          setShowImage(true);
          io.unobserve(item.target); // 停止观察当前元素，避免不可见时再次调用 callback 函数
        }
      });
    });
    if (targetRef.current) {
      io.observe(targetRef.current);
    }

    return () => {
      io.disconnect();
    };
  }, []);

  const handleImageError = () => {
    if (!isOnError) {
      setIsOnError(true);
      imageRef.current.src = getImageURL(url);
    }
  };

  return (
    <div className="experience-image-item">
      <img
        className="experience-image-thumb"
        src={getImageURL(thumbnail.url)}
        alt="我们的照片"
        ref={targetRef}
      />
      {showImage ? (
        <img
          className="experience-image"
          src={getImageURL(large.url)}
          ref={imageRef}
          onError={handleImageError}
          alt="我们的照片"
        />
      ) : null}
    </div>
  );
};

export default LazyImage;
