import React, { useEffect, useRef, useState } from 'react';
import cls from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { TExperience, TImage } from '../../../../libs/model';
import LazyImage from '../lazy-image';
import { formatDate } from '../../../../libs/utils';

interface IProps {
  experience: TExperience;
}

const Experience: React.FC<IProps> = (props: IProps) => {
  const { experience } = props;
  const {
    title, description, date, images,
  } = experience;
  const imageInfoList = images.data;
  const containerRef = useRef<any>();
  const imageBoxRef = useRef<any>();
  const [isAtLeft, setIsAtLeft] = useState(true);
  const [isAtRight, setIsAtRight] = useState(false);

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollWidth, scrollLeft, clientWidth } = container;
      const isLeft = scrollLeft === 0;
      const isRight = scrollWidth - scrollLeft === clientWidth;
      setIsAtLeft(isLeft);
      setIsAtRight(isRight);
    }
  };

  const handleClick = (isNext: boolean, scrollValue: number = window.innerWidth * 0.8 + 10) => {
    if (isNext) {
      containerRef.current.scrollLeft += scrollValue; // 向右滚动
    } else {
      containerRef.current.scrollLeft -= scrollValue; // 向左滚动
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="experience">
      <div className="experience-info">
        <div className="experience-title">{title}</div>
        <div className="experience-date">{formatDate(date)}</div>
        <div className="experience-description">{description}</div>
      </div>
      <div className="experience-image-box" ref={imageBoxRef}>
        <div className={cls('experience-images', { 'single-image': imageInfoList.length === 1 })} ref={containerRef}>
          {imageInfoList.map((image: TImage) => (
            <LazyImage image={image} key={image.id} />
          ))}
        </div>
        {!isAtLeft && imageInfoList.length > 1 ? <FontAwesomeIcon className="btn-left-arrow" icon={faChevronLeft} size="2x" onClick={() => handleClick(false)} /> : null}
        {!isAtRight && imageInfoList.length > 1 ? <FontAwesomeIcon className="btn-right-arrow" icon={faChevronRight} size="2x" onClick={() => handleClick(true)} /> : null}
      </div>
    </div>
  );
};

export default Experience;
