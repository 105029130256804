import { TPagination, TRresponse } from './model';

export const DATE_BY_FELL_IN_LOVE = '2022-04-02T17:51:54'; // 在一起的时间戳

export const BASE_URL = 'https://cms.chenjx.com.cn'; // 相册网址

export const defaultPagination: TPagination = {
  page: 1,
  pageSize: 10,
  pageCount: 1,
  total: 0,
};

export const defaultResponse: TRresponse = {
  data: [],
  meta: {
    pagination: defaultPagination,
  },
};
