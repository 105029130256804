import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './app.scss';
import Home from './pages/home';
import Photos from './pages/photos';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/photos" element={<Photos />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
