import axios from 'axios';
import { TRresponse } from './model';
import { BASE_URL, defaultResponse } from './constant';

export const getExperiences = (page = 1, pageSize = 10): Promise<TRresponse> => axios.get(`${BASE_URL}/api/experiences`, {
  params: {
    populate: 'images',
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
  },
}).then((result) => {
  const { data } = result;
  return data as TRresponse;
}).catch(() => defaultResponse);
