import React from 'react';
import './index.scss';

const Photos: React.FC = () => (
  <div className="photos">
    photos pages
  </div>
);

export default Photos;
