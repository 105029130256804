import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import './index.scss';

interface IProps {
  children?: any;
}

const Heart: React.FC<IProps> = (props: IProps) => {
  const { children = '' } = props;
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    let timeOut: any;
    if (showAnimation) {
      timeOut = setTimeout(() => {
        setShowAnimation(false);
      }, 500);
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [showAnimation]);

  return (
    <div className={cls('heart', { clicked: showAnimation })} onClick={() => setShowAnimation(true)}>
      <div className="heart-content" />
      <div className="heart-inner">
        {children}
      </div>
    </div>
  );
};

Heart.defaultProps = {
  children: null,
};

export default Heart;
