import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import Experience from './components/experience';
import Timer from './components/timer';
import Heart from './components/heart';
import {
  DATE_BY_FELL_IN_LOVE,
  defaultPagination,
} from '../../libs/constant';
import { getExperiences } from '../../libs/fetch';
import { TDataItem, TPagination, TRresponse } from '../../libs/model';

const Home: React.FC = () => {
  const [experienceList, setExperienceList] = useState<TDataItem[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pagination, setPagination] = useState<TPagination>(defaultPagination);
  const targetRefs = useRef<HTMLDivElement[]>([]);

  const scrollToNextElement = (index: number) => {
    if (index < targetRefs.current.length) {
      targetRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (experienceList.length === 0) {
      getExperiences(1, 100).then((result: TRresponse) => {
        const { data, meta } = result;
        const { pagination: newPagination } = meta;
        setExperienceList(data);
        setPagination(newPagination);
      });
    }
  });

  return (
    <div className="home">
      <div className="home-header">
        <div className="say-hey">
          <div className="hey-text">Heyyy</div>
          <div className="nick">兰兰</div>
        </div>
        <div className="heart-box">
          <Heart>
            <div className="timer-box">
              <div className="timer-content">
                <div>我们在一起已经</div>
                <Timer timestamp={Number(new Date(DATE_BY_FELL_IN_LOVE))} />
                <div>啦</div>
              </div>
            </div>
          </Heart>
        </div>
        <FontAwesomeIcon className="btn-down-arrow" icon={faChevronDown} size="3x" onClick={() => scrollToNextElement(0)} />
      </div>
      {experienceList.map((experience: TDataItem, index: number) => (
        <div className="experience-item" key={experience.id} ref={(el: HTMLDivElement) => { targetRefs.current[index] = el; }}>
          <Experience experience={experience.attributes} />
          <FontAwesomeIcon className="btn-down-arrow" icon={faChevronDown} size="3x" onClick={() => scrollToNextElement(index + 1)} />
        </div>
      ))}
      <div className="experience-item" ref={(el: HTMLDivElement) => { targetRefs.current[experienceList.length] = el; }}>
        <div className="unfinished">未完待续...</div>
      </div>
    </div>
  );
};

export default Home;
