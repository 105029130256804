import React, { useEffect, useState } from 'react';
import './index.scss';

interface IProps {
  timestamp: number;
}

const Timer: React.FC<IProps> = (props: IProps) => {
  const { timestamp } = props;
  const [seconds, setSeconds] = useState(Math.floor((Date.now() - timestamp) / 1000));
  // 位运算取整得出现在时间和timestamp的时间差（秒数）
  const minutes = Math.floor(seconds / 60) % 60;
  const hours = Math.floor(seconds / 60 / 60) % 24;
  const days = Math.floor(seconds / 60 / 60 / 24);

  useEffect(() => {
    const outTime = setTimeout(() => {
      setSeconds(Math.floor((Date.now() - timestamp) / 1000));
    }, 1000);
    return () => {
      clearTimeout(outTime);
    };
  }, [seconds, timestamp]);

  return (
    <div className="timer">
      <div>
        {days}
        天
      </div>
      <div>
        {hours}
        小时
      </div>
      <div>
        {minutes}
        分钟
      </div>
      <div>
        {seconds % 60}
        秒
      </div>
    </div>
  );
};

export default Timer;
